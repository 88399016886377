.onboardingview {
    color: $white;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background: $secondary;
    padding: 5%;
    margin: auto;
    text-align: center;

    .progress {
        position: fixed;
        top: -10px;
        padding: 0;

        .progress-bar {
            background: $background-degrade
        }
    }

    .sombra {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;
        border-radius: 40px;
        box-shadow: inset 0 0 23px 23px $secondary, inset 0 0 3px 2px $secondary;
        background: $background-degrade;
        
    }

    .view {
        color: $secondary;
        margin: auto;
        text-align: center;
        background-color: #fff;
        width: 100%;
        min-height: 400px;
        padding-bottom: 40px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            cursor: pointer;
        }

        .container {
            h2 {
                padding: 25px;
            }

            p {
                width: 50%;
                margin: auto;
            }

            img {
                margin: auto;
                text-align: center;
                width: 100%;
            }
            #player{
                width: 90%;
            }
            iframe{
                width: 100%;
            }

            .content {
                margin: auto;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

    }
}