.nfe-options{
    // display: flex;
    border: 0.5px solid $border;
    padding: 20px;
    text-align: center;
    border-radius: 4px;
    height: 180px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .badge{
        position: absolute !important;
        right: 40px;
    }
    h3{
        font-size: 20px;
        margin-top: 15px;
    }
    svg{
        width: 40px;
        background-color: $terciary;
        border-radius: 50%;
        padding: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    img{
        margin-left: 30px !important;
    }
    &:hover{
       border: 1px solid $primary;
    }
   
}
.offcanvas{
    width: 60% !important;
}
.paymentData{
    border: 0.5px solid $border;
    padding: 10px;
    // margin: 10px;
    width: 95%;
    margin: auto !important;
    margin-top: 25px !important;
    // margin-left: 10px !;
    h5{
        padding-bottom: 15px;
        margin-bottom: 20px;
        padding-top: 10px;
        border-bottom: 0.5px solid $border;
        font-weight: 700;

    }
}