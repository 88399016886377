.main {
    margin: 20px
}

.menu1 {
    margin: 0px;
    margin-left: -12px;
    padding: 20px;
    height: 100vh;
    background-color: $white;
    border: none;
    overflow-y: auto;
    // box-shadow: 0px 1px 3px rgba(18, 20, 32, 0.2);
    position: fixed;
    top: 0;
    width: 111px;
    text-align: center;
    z-index: 998;

    img {
        width: 50px;
        margin-top: 15px;
        margin-bottom: 25px;
    }

    ul {
        padding: 0;
        text-align: center;

        li {
            list-style: none;
            margin-top: 30px;
            cursor: pointer;

            

            svg {
                width: 20px;
            }
        }
    }
}

.menu ul li:nth-child(1).active~.indicator {
    transform: translateY(calc(70px * 0));

}

.menu ul li:nth-child(2).active~.indicator {
    transform: translateY(calc(70px * 1));
}

.menu ul li:nth-child(3).active~.indicator {
    transform: translateY(calc(70px * 2));
}

.menu ul li:nth-child(4).active~.indicator {
    transform: translateY(calc(70px * 3));
}

.menu ul li:nth-child(5).active~.indicator {
    transform: translateY(calc(70px * 4));
}

.menu ul li:nth-child(6).active~.indicator {
    transform: translateY(calc(70px * 5));
}

.menu ul li:nth-child(7).active~.indicator {
    transform: translateY(calc(70px * 6));
}

.menu ul li:nth-child(8).active~.indicator {
    transform: translateY(calc(70px * 7));
}

.menu ul li:nth-child(1).active~.indicator::before {
    background-color: #f53b57;
}

.main-content {
    padding-left: 112px !important;
}

.menu1 {
    margin: 0px;
    // margin-left: -12px;
    padding: 20px;
    height: 100%;
    background-color: $white;
    // border-right: 1px solid $border;
    box-shadow: 0px 0px 10px 0px #30495F1A;
    position: fixed;
    width: 90px !important;
    top: 0;

    img {
        width: 40px;
        margin-top: 15px;
        margin-bottom: 25px;
    }

    ul {
        padding: 0;
        text-align: center;
        text-align: center;
        display: inline-block;


        li {
            list-style: none;
            width: 38px;

            cursor: pointer;

           
            svg {
                width: 22px;
            }
        }
    }
}

.bg-body-terciary {
    background: #FEFEFF;
    border-bottom: 0.5px solid $border;

    .dropdown-toggle::after {
        display: none;
    }

    .menu-user {
        width: 40px;
        margin-top: 10px;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        cursor: pointer;
        // border: 1px solid $border;
        border: none;
        border-radius: 50px;
        background-color: $white;
        &:hover{
            opacity: 0.6;
        }

        .dropdown-menu {
            font-size: 12px;

            .dropdown-item {
                font-size: 12px !important;
            }
        }
        
    }
    .menu-user-open{
        display: none;
        width: 250px;
        background-color: $white;
        box-shadow: 0px 0px 20px 0px #30495F1A;
      
        position: absolute;
        margin-top: 45px;
        border-radius: 6px;
        right: 20px;
        z-index: 1000;
        .info-user{
            text-align: left;
            // border-bottom: 1px solid $border;
          
            margin-bottom: 0px;
            padding: 15px;
            background: $white;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            svg{
                width: 45px;
                margin-bottom: 10px;
            }
            h5{
                font-size: 16px;
                font-weight: 700;
                margin-top: 10px;
            }
        }
        ul {
            list-style: none;
            padding: 10px;
            margin: 0;
            hr{
                padding: 0px;
                margin-right: 25px;
                opacity: 0.1;
                margin: 0px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            li{
                font-size: 13px;
                cursor: pointer;
                padding: 10px;
                margin: 0;
                display: flex;
                justify-content: left;
                align-items: center;
                svg{
                    width: 16px;
                    margin-right: 10px;
                }
                a{
                    text-decoration: none;
                    color: var(--text-color);
                }
                &:hover{
                    opacity: 0.6;
                }
                &:hover{
                //    text-decoration: underline;
                    border-radius: 6px;
                }
            }
           
        }
        
    }
}
.show{
    display: block !important;
}


.footer-menu {
    position: absolute;
    bottom: 10px;
    left: 0;
    padding: 10px;
    padding-left: 20px;
    width: 100%;
    border-top: 0.5px solid rgba(18, 20, 32, 0.2);

    .dropup {
        button {
            text-align: left;
            width: 10px;
            padding: 20px !important;

            svg {
                width: 25px;
                margin-top: -20px;
                margin-left: -12px;
            }
        }
    }

    .dropdown-toggle::after {
        border-right: 0px !important;
        border-bottom: 0px !important;
        border-left: 0px !important;
    }

    .dropdown-menu.show {
        border: none;
        box-shadow: 0px 1px 3px rgba(18, 20, 32, 0.2);
    }

    .dropdown-item {
        svg {
            width: 35px;
            padding: 10px;
        }
    }
}



.menu ul li {
    padding: 5px;
    border-radius: 8px;
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 20px !important;

    a {
        color: var(--text-color);
    }

}

.menu {
    .last-menu {
        li{
            margin-top: 10px;
            margin-bottom: 10px !important;
        }
      
    }
}

.menu ul li.active {
    background-color: $primary  !important;
    color: $white !important;
    // opacity: 0.5;
    a{
        color: $white !important;
    }
}

.menu ul li:hover {
    background-color: $primary  !important;
    color: $white !important;
    a{
        color: $white !important;
    }
    // opacity: 0.5;
}




