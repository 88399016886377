.color-selector {
    width: 100% !important;
}

.preview-logo {
    border: 1px solid #eee;
    border-radius: 1rem;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    img {
        width: auto;
        padding: 1rem;
        object-fit: cover;
    }
}