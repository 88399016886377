.badge{
    border-radius: 8px !important;
   
}
.bg-danger{
    background: $red !important;
}
.bg-warn{
    background-color: $warn !important;
    color: var(--text-color) !important;
    font-size: 12px !important;
}
.bg-success-ow{
    background-color: $primary !important;
    color: $white !important;
}
.bg-pastel{
    background-color: $primarypastel !important;
    color: $white !important;
}
.bg-degrade{
    background: $background-degrade !important;
    color: $white !important;
}
.text-link{
    font-size: 12px !important;
}