.btn:disabled{
    opacity: 0.2 !important;
    cursor: not-allowed !important;
}
.btn-link{
    text-decoration: none !important;
}

.btn-link:hover{
    text-decoration: underline !important;
}

.btn-primary {
    background-color: $primary  !important;
    border-color: $primary !important;
    color: $white !important;
    height: 45px;
    line-height: 45px;
    border-radius: 6px !important;
    padding: 0 35px !important;
    font-size: 15px !important;
    font-weight: 600;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
}

.btn-primary:hover {
    background-color: $primary  !important;
    border-color: $primary  !important;
    color: $white !important;
}


.btn-secondary {
    background-color: $terciary  !important;
    border-color: $white;
    color: $white !important;
    height: 45px;
    line-height: 45px;
    border-radius: 6px !important;
    padding: 0 35px !important;
    font-size: 15px !important;
    font-weight: 600;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
}

.btn-secondary:hover {
    background-color: $white  !important;
    border-color: $terciary  !important;
    color: $terciary  !important;
}


.btn-terciary {
    background-color: $terciary  !important;
    border-color: $terciary;
    height: 45px;
    line-height: 45px;
    border-radius: 6px !important;
    padding: 0 35px !important;
    font-size: 15px !important;
    font-weight: 600;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
}

.btn-terciary:hover {
    background-color: $white  !important;
    border-color: $terciary  !important;
    color: $secondary  !important;
}

.btn-pastel {
    background-color: $primarypastel  !important;
    border-color: $primarypastel;
    color: $secondary;
    height: 45px;
    line-height: 45px;
    border-radius: 6px !important;
    padding: 0 35px !important;
    font-size: 15px !important;
    font-weight: 600;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
    
}

.btn-pastel:hover {
    background-color: $secondary  !important;
    border-color: $primarypastel  !important;
    color: $primarypastel  !important;
}

.btn-white {
    background-color: $white  !important;
    border-color: #dee2e6 !important;
    color: #000 !important;
    height: 45px;
    line-height: 45px;
    border-radius: 6px !important;
    padding: 0 35px !important;
    font-size: 15px !important;
    font-weight: 600;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
    svg{
        color:  #000 !important;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        margin: 2px;
        border: 2px solid var(--text-color);
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--text-color) transparent transparent transparent;
    }
}

.btn-white:hover {
    opacity: 0.6;
}
.btn-success {
    background-color: $green  !important;
    border-color: $green !important;
    color: $white;
    height: 45px;
    line-height: 45px;
    border-radius: 6px !important;
    padding: 0 35px !important;
    font-size: 15px !important;
    font-weight: 600;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
    svg{
        color: $white;
        width: 25px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        margin: 2px;
        border: 2px solid $secondary;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $secondary transparent transparent transparent;
    }
}

.btn-success:hover {
    opacity: 0.6;
}
.btn-danger {
    background-color: $red  !important;
    border-color: $red !important;
    color: $white;
    height: 45px;
    line-height: 45px;
    border-radius: 6px !important;
    padding: 0 35px !important;
    font-size: 15px !important;
    font-weight: 600;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
    svg{
        color: $white;
        width: 25px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        margin: 2px;
        border: 2px solid $secondary;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $secondary transparent transparent transparent;
    }
}

.btn-danger:hover {
    opacity: 0.6;
}



// loading
.lds-ring {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 2px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}