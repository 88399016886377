.notFound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    h4{
        margin-bottom: 20px;
        margin-top: 40px;
    }
    img{
        margin-top: -45px;
    }
}