.card-graph{
    border-radius: 20px !important;
    padding: 2%;
    background-color: $white;
    border: none;
    box-shadow: 0px 0px 10px 0px #30495F1A;
    &:hover .hover{
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 20px;
        padding-top: 30px;
        justify-content: center;
        align-items: center;
        align-self: center;
        align-content: center;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.848);
        transition: 0.5s;
        button{
            margin: 5px;
        }
    }
    .hover{
        display: none;
        transition: 0.5s;
    }
    .card-bottom{
        width: 110%;
        margin: -5%;
        margin-top: 5%;
        padding: 15px;
        background: $primarypastel;
        color: $secondary;
        background-blend-mode: multiply;
        border-radius: 0px 0px 20px 20px;
        img{
            width: 30px;
            margin-left: 10px;
            margin-top: 5px;
        }
        p{
            padding-bottom: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 12px;
        }
        h6{
            margin: 0;
            font-size: 14px;
            font-weight: 600;
        }
    }
}
