.float-button-card{
position: relative;

button {
    position: absolute;
    right: 0;
    padding: 0 !important;
    padding-left: 10px !important;

    svg{
        padding: 0;
        width: 40px;
    }
}
}

.card-colaboradores {
    min-height: 75px;
    
    h2 {
        color: var(--text-color);
        font-weight: 900;
    }

    p {
        color: var(--text-color);
        font-style: italic;
        font-weight: 500;
    }
}

.card-satisfacao {
    min-height: 75px;

    h2 {
        color: var(--text-color);
        font-weight: 900;
    }

    p {
        color: var(--text-color);
        font-style: italic;
        font-weight: 500;
    }
}

.card-questionarios {
    min-height: 75px;

    h2 {
        color: var(--text-color);
        font-weight: 900;
    }

    p {
        color: var(--text-color);
        font-style: italic;
        font-weight: 500;
    }
}

.promotion-card {
    height: 100px;
    background: rgb(251, 212, 176);
    background: $background-degrade;
    border-radius: 30px;

    color: $secondary;
    text-align: center;

    p {
        padding-top: 30px;
        font-size: 22px;
    }
}

.pendencies {

    
    h4 {
        color: var(--text-color);
        font-weight: 600;

        svg {
            color: var(--text-color);
            width: 45px;
        }
    }

    .cardPendency {
        background: $white;
        border-bottom: 1px solid $border;
        width: 100% !important;
        border-radius: 10px;
        padding: 15px;
        color: var(--text-color);
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 14px;
            line-height: 18px;
        }
        button{

        }
    }
}

.duvidas {
    h4 {
        color: $white;
        font-weight: 600;
        margin-bottom: 0px;

        svg {
            color: $white;
            width: 45px;
        }
    }

    .form {
        border: 0.5px solid $border !important;
        box-shadow: none;
        width: 100% !important;
        border-radius: 10px !important;
        margin-top: 10px;
    }
}

.comunicados {
    h4 {
        color: var(--text-color);
        font-weight: 600;

        // margin-bottom: 0px;
        svg {
            color: var(--text-color);
            width: 45px;
        }
    }

    .line-item {
        border-bottom: 0.5px solid $border !important;
        box-shadow: none;
        width: 98% !important;
        padding: 20px;
        max-height: 100px;
        overflow-y: auto;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        position: relative;
        // align-items: center;
        align-self: center;

        img {
            min-width: 85px;
        }

        p {
            font-size: 12px;
        }

        a {
            text-align: center;
            font-size: 13px;
            line-height: 16px;
            position: absolute;
            right: 5px;
            top: 5px;
            
            border-radius: 50px;
            border: 0.5px solid $border;

            svg {
                color: var(--text-color);
                width: 35px;
            }
        }
    }
}

.img-nothing-found-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 50%;
    }
}