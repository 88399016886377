@import './components/card';
@import './components/card_graph';
@import './components/button';
@import './components/badge';
@import './components/table';
@import './components/loading';
@import './components/dragndrop';
@import './components/notfound';
@import './components/pagination';
@import './components/overlay';
@import './pages/wait';
@import './pages/onboarding';
@import './pages/login';
@import './pages/dashboard';
@import './pages/employees';
@import './pages/onboardingView';
@import './pages/nfes';
@import './pages/quiz';
@import './pages/config';
@import './pages/bank';
@import './core/base';
@import './core/form';
@import "primereact/resources/themes/lara-light-indigo/theme.css";

body {
    background-color: #f4f8f13a !important;
    color: var(--text-color);
    // background-color: #fcf8ffd1 !important;
}

.bg-danger1 {
    background: $red  !important;
}

.logo-footer {
    width: 100%;
    text-align: center;
    margin: auto;
    
    font-size: 14px;
    filter: opacity(0.5);

    img {
        margin-top: -20px;
        mix-blend-mode: multiply;
        width: 150px !important;

    }
}
.text-12{
    font-size: 12px;
}
.logo-footer-app {
    
    font-size: 12px;
    filter: opacity(0.5);
    position: fixed;
    bottom: 10px;
    right: 20px;

    img {
        mix-blend-mode: multiply;
        width: 100px !important;
        margin-left: 5px;

    }
}