@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "./vars.module.scss";
@import "./styles/main.scss";


body {
    padding: 0 !important;
    margin: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $body-color;
    // background-color: $body-color  !important;
    max-width: 100%;
    overflow: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Plus Jakarta Sans', sans-serif;
}

a{
    color: var(--text-color) !important;
    font-size: 14px;
}
p,
span,
blockquote {
    font-size: 14px;
}
.blend-img{
    mix-blend-mode: multiply;
}
.bg-wait {
    width: 100%;
    height: 100%;
    padding: 5%;
    margin: 1%;

    border-radius: 20px;
    text-align: center;
    background: rgb(251, 212, 176);
    background: linear-gradient(90deg, rgba(251, 212, 176, 1) 0%, rgba(239, 193, 234, 1) 50%, rgba(168, 177, 239, 1) 100%);
}

.form {
    /* width: 40%; */
    text-align: left;
    margin: auto;
    border: none !important;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(18, 20, 32, 0.2);
    border-radius: 30px !important;
    padding: 30px;
    margin-top: 30px;

}

.tp-hero__title-shape {
    position: absolute;
    bottom: 22px;
    left: 0;
    right: 0;
    z-index: 1;
}

.tp-hero__title-shape svg path.line1 {
    stroke-width: 2.7px;
    stroke: #6865FF;
    stroke-dasharray: 2300;
    stroke-dashoffset: 3200;
    animation: dash 2s linear both;
}

.form-control {
    border-radius: 6px !important;
    padding: 10px !important;
    padding-left: 20px !important;
}

.form-label {
    font-weight: 600;
}

.form-select {
    border-radius: 6px !important;
    padding: 10px !important;
}


.sucesso {
    text-align: center;
}

.disabled {
    cursor: not-allowed !important;
}
.trial{
    display: flex;
    background-color: $white;
    border: 0.5px solid $border;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    height: 30px;
    margin-top: 10px;
}
.onboarding-e{
    background-color: #FFFFFF;
    position:absolute;
    overflow: hidden;
    height: 100vh;
    padding-top: 20px;
    top: -40px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    .title{
        padding-left: 10%;
        padding-top: 5%;
    }
}
td{
    vertical-align: middle;

}
svg#freepik_stories-push-notifications:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-push-notifications.animated #freepik--background-complete--inject-8 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

svg#freepik_stories-push-notifications.animated #freepik--Device--inject-8 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

svg#freepik_stories-push-notifications.animated #freepik--Notification--inject-8 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay: 0s;
}

svg#freepik_stories-push-notifications.animated #freepik--Character--inject-8 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay: 0s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: inherit;
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
.modal{
    z-index: 1000 !important;
}
.modal-backdrop{
    z-index: 999 !important;
}