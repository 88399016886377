.bg-wait {
    font-family: 'Plus Jakarta Sans', sans-serif;
    width: 100%;
    height: 100%;
    padding: 5%;
    margin: 1%;
    border-radius: 20px;
    text-align: center;
    background: rgb(251, 212, 176);
    background: $background-degrade
}

.form {
    /* width: 40%; */
    text-align: left;
    margin: auto;
    border: none !important;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(18, 20, 32, 0.2);
    border-radius: 30px !important;
    padding: 30px;
    margin-top: 30px;

}

.tp-hero__title-shape {
    position: absolute;
    bottom: 22px;
    left: 0;
    right: 0;
    z-index: 1;
}

.tp-hero__title-shape svg path.line1 {
    stroke-width: 2.7px;
    stroke: $primary;
    stroke-dasharray: 2300;
    stroke-dashoffset: 3200;
    animation: dash 2s linear both;
}

.form-control {
    border-radius: 50px !important;
    padding: 10px !important;
    padding-left: 20px !important;
}

.form-label {
    font-weight: 600;
}


.sucesso {
    text-align: center;
}