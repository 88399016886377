.bank-info{
    background-color: $white;
    width: 100%;
    border: 1px solid $border;
    border-radius: 6px;
    padding: 20px;
    h6{
        font-weight: 700;
    }
    .head{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        button{
            background-color: $white;
            border: none;
            color: var(--text-color);
            display: flex;
            svg{
                width: 16px;
                margin-right: 5px;
                margin-top: 5px;
            }
        }
    }
}