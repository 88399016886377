.robo {
    position: absolute;
    bottom: 0;
}

.shape {
    position: absolute;
    right: 15%;
}

.shape2 {
    position: absolute;
    right: 0%;
    bottom: -0%;
    z-index: -1;
}

.login{
    width: 100vw;
    overflow: hidden;
}
.formLogin {

    margin: auto;
    width: 35%;
    z-index: 3;
    margin-top: 5%;

    margin-bottom: 50px;
    padding: 5%;
    background-color: $white  !important;
    color: var(--text-color) !important;
    box-shadow: 0px 0px 10px 0px #30495F1A;
    border: none !important;
    border-radius: 10px !important;

    @media (max-width: 720px) {
        width: 85%;
        margin-top: 10vh;
        padding-top: 80px;
    }

    .icon {
        position: relative;
        width: 20px;
        z-index: 10;
        margin-top: -36px;
        margin-left: 90%;
        cursor: pointer;
    }

    form {
        z-index: 4;
    }

    .badge-info {
        margin-left: 0px;
        margin-bottom: 40px;
    }

    .logo-login {
        margin-bottom: 10px;
    }

    h2,
    p {
        text-align: center;
        // margin-top: -20px;
    }

    h2 {
        margin-top: -40px;
    }

    p {
        margin-bottom: 10%;
    }

    button {
        width: 200px;
    }

    .sso {
        button {
            width: 250px;
            border: 0.5px solid #dee2e6;
            padding: 10px;
            border-radius: 50px;
            margin-left: 25%;
            margin-top: -30px;
        }

        p {
            position: absolute;
            margin-top: 10px;
            margin-left: 12%;
            background: $body-color;
            padding: 20px;
            color: #c0c3c6
        }

        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 0.5px solid #dee2e6;
    }
}

.imgRegister {
    border-radius: 30px;
    height: 100vh;
    background: $background-degrade;
    @media (max-width: 720px) {
        height: 32vh !important;
        margin-bottom: 20px;
   
    }
    h3 {
        padding: 20px 40px 10px 40px;
        font-weight: 800;
        margin-top: 20px;
    }

    p {
        padding: 0px 0px 0px 40px;
        margin-top: 20px;
    }

    .log {
        padding: 0px 0px 0px 40px;
        margin-top: 40px;
    }
}

.formRegisterEmp {
    margin: auto;
    width: 70%;
    margin-top: 4%;
    z-index: 3;
    @media (max-width: 720px) {
        width: 100%;
   
    }
    .icon {
        position: relative;
        width: 20px;
        z-index: 10;
        margin-top: -36px;
        margin-left: 90%;
        cursor: pointer;
    }

    form {
        z-index: 4;

        .form-check-inline {
            border: 0.5px solid #dee2e6;
            border-radius: 30px;
            padding: 10px;
            padding-left: 50px;
            padding-right: 50px;

        }
    }



    .badge-info {
        margin-left: 0px;
        margin-bottom: 40px;
    }

    .logo-login {
        margin-bottom: 10px;
    }

    h2,
    p {
        text-align: center;
        // margin-top: -20px;
    }

    h2 {
        margin-top: -40px;
    }

    p {
        margin-bottom: 10%;
    }

    button {
        width: 200px;
    }

    .sso {
        button {
            width: 250px;
            border: 0.5px solid #dee2e6;
            padding: 10px;
            border-radius: 50px;
            margin-left: 25%;
            margin-top: -30px;
        }

        p {
            position: absolute;
            margin-top: 10px;
            margin-left: 12%;
            background-color: $white;
            padding: 20px;
            color: #c0c3c6
        }

        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 0.5px solid #dee2e6;
    }
}