.qr-code {
    text-align: center;
    padding: 5%;
}

.sitaucao-receita {
    strong {
        display: block;
    }

    .col {
        padding: 10px;
        margin: 5px;
        border: 0.5px solid #ccc;;
    }
}