.newType {
    h5 {
        margin-bottom: 40px;
        margin-top: 20px;
    }
}

.typeOption {
    padding: 20px;
    border: 1px solid $border;
    border-radius: 6px;
    text-align: center;
    min-height: 140px;

    // display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
        width: 40px;
        margin-top: 10px;
    }

    p {
        font-size: 20px;
        margin-top: 15px;
    }
}
.activeStep{
    border: 2px solid $primary;
}
.step{
    margin-top: 10px;
    width: 100%;
    background-color: $white;
    border: 1px solid $border;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    cursor: grab;

}