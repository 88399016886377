.multSelect {
    border-radius: 6px;

    img {
        margin-right: 5px;
    }

    .select__control {
        border-radius: 6px;
        padding: 4.3px;
        border-color: $border;
        height: 45px;
        overflow-y: scroll;
    }

    .select__indicators {
        position: absolute;
        right: 0;
        top: 6px;
        padding: 0px;
    }
}

.p-multiselect {
    border-radius: 6px;
    height: 46px;
}

.image-inside-right {
    position: absolute;
    left: 60%;

    svg {
        // width: 25px;
        height: 50px;
        color: $green;
    }

    // margin-left: -20px;
}