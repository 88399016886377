:root {
    --primary-color: #00851f;
    --secondary-color: #8D897E;
    --text-color: #1E2124;
}

$primary: var(--primary-color);    
$secondary: var(--secondary-color);  

$terciary: #2b79df;   
$primarypastel: #9cdba2;   
$pink:#f3bff0; 
$background-degrade: linear-gradient(90deg, #EFF7E6 0%, #EEF7E1 50%, #D1F593 100%);
$red: #de407f;
$green: #93f595;
$black: #3a3a3a;
$black-deep: #000000;
$white: #ffffff;
$body-color: #FBFBFB;
$border:#dee2e6;
$warn: #EBB341;

:export {
    primary: $primary;
    secondary: $secondary;
    terciary: $terciary;
    secondary: $secondary;
    background_degrade: $background-degrade;
    red: $red;
    border: $border;
    green: $green;
    black: $black;
    red: $red;
    green: $green;
    black: $black;
    black_deep: $black-deep;
    white: $white;
    body_color: $body-color;
    pink: $pink;
    primarypastel: $primarypastel
}