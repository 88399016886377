.card{
    
   
    padding: 5%;
    background-color: $white !important;
    color: var(--text-color) !important;
    box-shadow: 0px 0px 10px 0px #30495F1A;
    border: none !important;
    border-radius: 10px !important;
    // border: 1px solid $border;
    &:hover .hover{
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        border-radius: 10px;
        padding-top: 30px;
        justify-content: center;
        align-items: center;
        align-self: center;
        align-content: center;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.278);
        transition: 0.5s;
        button{
            margin: 5px;
        }
    }
    .hover{
        display: none;
        transition: 0.5s;
    }
    svg{
        width: 80px;
        margin: auto;
        color: var(--text-color);
        padding: 10px;
    }
    .icon{
        width: 100px;
        margin: auto;
        padding: 20px;
    }
    .card-bottom{
        width: 110%;
        margin: -5%;
        margin-top: 5%;
        padding: 15px;
        background: $white;
        color: var(--text-color);
        background-blend-mode: multiply;
        border-radius: 0px 0px 10px 10px;
        img{
            width: 30px;
            margin-left: 10px;
            margin-top: 5px;
        }
        p{
            padding-bottom: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 12px;
        }
        h6{
            margin: 0;
            font-size: 14px;
            font-weight: 600;
        }
    }
    .status-active{
        width: 15px;
        height: 15px;
        border-radius: 20px;
        background-color: $green;
        box-shadow: 0px 0px 8px 2px $green;
        position: absolute;
        right: 5%;
        top: 10px;
    }
    .status-inactive{
        width: 15px;
        height: 15px;
        border-radius: 20px;
        background-color: $red;
        box-shadow: 0px 0px 8px 2px $red;
        position: absolute;
        right: 5%;
        top: 10px;
    }
}
