.quiz{
    width: 80%;
    margin: auto;
    
}
.remove-question{
        button{
            // width: 30px;
            border: none;
            background-color: transparent;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 55px;
            background-color: $red;
            border: 0.5px solid $red;
            position:absolute;
            top:20px;
            right: 20px;
            svg{
                width: 45px;
                color: $white;
            }
        }
}
.add-options{
    background-color: transparent;
    border: none;
    border: 0.5px solid $border;
    color: var(--text-color);;
    padding: 0;
    border-radius: 100%;
    svg{
        width: 40px;
    }
}
.action-buttons{
    a{
        margin: 10px;
        color: var(--text-color);
    }
    .delete{
        color: $red !important;
    }
    
}
.quizview{
    width: 80%;
    background-color: $white;
    margin: auto;
    margin-top: 20px;
   
    text-align: center;
    padding: 5%;
    // border: 1px solid $border;
    img{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .question{
        border: 0.5px solid $border;
        padding: 40px;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.options-view{
    padding-top: 20px;
    margin: auto;
    margin-bottom: -20px;
}